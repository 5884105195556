import StickyContact from "./StickyContact";
import React from "react";
import { IoIosArrowBack } from "react-icons/io";
import SocialNetworkBar from "./SocialNetworkIcons";
import { Link } from "react-router-dom";

export const AutreSupport: React.FC = () => {
  return (
    <div className=" flex flex-wrap mx-6% w-90%">
      <StickyContact />
      <div className="flex flex-wrap ">
        <div className="items-center flex mt-10%">
          <Link to="/coller">
            <IoIosArrowBack className="w-10 h-12" />
          </Link>
          <div>
            <h1 className="text-2xl font-bold  ">Solution</h1>
          </div>
        </div>
      </div>
      <div className=" text-xl font-regular flex flex-wrap justify-content-enter mt-2%">
        <p>Merci de contacter notre hotline pour plus d'informations :</p>
      </div>
      <div className="flex flex-col items-center justify-items-center  ">
        <div className="flex rounded-lg bg-orange justify-center w-90% mt-6 mb-4">
          <a
            href="mailto:hotline@fr.sika.com?subject=Support%20de%20collage%20"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button
              className="flex py-6%
              text-black text-lg  items-center"
            >
              {" "}
              Contacter notre hotline
              <img
                src={require("../img/open-in.png")}
                alt="external link"
                className="ml-2"
              />
            </button>
          </a>
        </div>
        <SocialNetworkBar />
        <p className="font-normal text-c text-justify mt-2%">
          Les informations et produits proposés ne sont pas de nature
          contractuels, l’Utilisateur devra impérativement valider l’utilisation
          des produits proposés en contactant la hotline SIKA à l’adresse{" "}
          <a
            href="mailto:hotline@fr.sika.com"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-700 active:text-blue-800"
          >
            hotline@fr.sika.com
          </a>
          . A défaut la responsabilité de la société SIKA ne saurait être
          engagée. Pour plus d’informations consulter les Conditions Générales
          d’Utilisation.
        </p>
      </div>
    </div>
  );
};
