import StickyContact from "./StickyContact";
import React from "react";

interface FirstChoiceProps {
  state: any;
  handleNavigation: (key: any) => void;
}

export const FirstChoice: React.FC<FirstChoiceProps> = ({
  state,
  handleNavigation,
}) => {
  let ListChoice = Object.keys(state.firstChoice);
  return (
    <div className="overflow-auto w-85% flex justify-center mx-7%">
      <StickyContact />
      <div className="items-center flex flex-col mt-6">
        <h1 className="text-xl font-bold ">{state.title}</h1>
        <p className="text-lg font-regular mt-4">{state.subtitle}</p>
        {ListChoice.map((key: any) => (
          <div className="relative" key={key}>
            <button onClick={() => handleNavigation(key)}>
              <img
                src={require(`../img/imgAccueil/${state.firstChoice[key]?.img}`)}
                alt=""
                className="w-64 h-64 rounded-2xl my-7 object-cover object-center brightness-75"
              />
              <h1 className=" absolute top-45% left-1/2 -translate-x-1/2 -translate-y-1/2 font-bold text-5xl text-white">
                {state.firstChoice[key].placeholder}
              </h1>
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};
