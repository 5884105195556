import { IoIosArrowBack } from "react-icons/io";
import { Link } from "react-router-dom";

export default function Politique() {
    return (
        <div className="items-start flex flex-col mx-4%">
            <div className="items-center flex mt-3">
                <Link to="/">
                    <IoIosArrowBack className="w-10 h-12" />
                </Link>
                <h1 className="text-2xl font-bold">Politique de confidentialité relative a la protection des données personnelles
                </h1>
            </div>
            <div className="font-normal text-sm mt-4% text-justify">
                <p>
                    La présente note d’information sur la protection des données personnelles décrit
                    comment la société Sika France SAS, dont le siège social est situé 84 Rue Edouard
                    Vaillant 93350 Le Bourget (« Sika France » ou « nous » ou « notre »), en qualité de
                    responsable de traitement traite vos données personnelles et autres informations quand
                    vous utilisez le « Site », et ce conformément au règlement général sur la protection
                    des données (« RGPD »).
                </p>
            </div>
            <div className="my-2%">
                <h2 className="text-lg font-bold justify-center mt-4%"> 1. CATÉGORIES DE DONNÉES PERSONNELLES, FINALITÉS DE TRAITEMENT, BASE LÉGALE ET SOURCE
                </h2>

                <h3 className="mb-2% text-lg font-semibold  justify-center">a. Données personnelles fournies volontairement par vos soins</h3>
                <p className="text-justify font-normal text-sm mb-2%">Si vous devenez utilisateur de notre Site, vous pourrez être amenés à nous fournir les données personnelles vous concernant.<br />

                    Nous traitons ces données personnelles dans le but de vous fournir nos services. La base légale de ce traitement est notre intérêt légitime pour faciliter votre navigation sur le Site (cf. article 6.1 f) du RGPD).<br />

                    Si par le biais du Site, il est vous proposé de vous abonner à notre newsletter, il vous sera demandé de fournir votre adresse électronique, sous réserve de votre accord. Le traitement de votre adresse électronique à cette fin repose sur votre consentement (article 6.1 a) du RGPD).<br />
                </p>
                <h3 className="mb-2% text-lg font-semibold  justify-center">b. Utilisation de traceurs par le Site
                </h3>
                <p className="text-justify font-normal text-sm">Des traceurs provenant de tierces parties pouvant être déposés sur votre terminal dans le cadre de l’utilisation du Site. <br />

                    Nous n’exerçons aucun contrôle sur le dépôt de tels traceurs. Pour de plus amples informations, nous vous invitons à consulter les politiques de confidentialités de ces plateformes tierces.
                </p>
                <h2 className="text-lg font-bold justify-center mt-6% "> 2. DESTINATAIRES
                </h2>
                <h3 className="mb-2% text-lg font-semibold  justify-center">a. Transfert aux prestataires de services
                </h3>
                <p className="text-justify font-normal text-sm mb-2%">Sika France peut être amenée à engager des prestataires de services externes
                    pour lui fournir certains services, tels que des prestataires de services du Site, prestataires de services de marketing ou prestataires de services de support informatique. En fournissant de tels services, les prestataires de services externes peuvent avoir accès à et/ou traiter vos données personnelles en qualité de sous-traitants.<br />
                    Ces prestataires de services externes seront soumis à des obligations contractuelles leur imposant notamment de mettre en place les mesures de sécurité techniques et organisationnelles appropriées, afin de sauvegarder les données personnelles et les traiter uniquement en respectant les instructions données par Sika France.
                </p>
                <h3 className="mb-2% text-lg font-semibold  justify-center">b. Autres destinataires
                </h3>
                <p className="text-justify font-normal text-sm mb-2%">Certains de nos collaborateurs gérant le Site et fournissant des services informatiques peuvent être des collaborateurs des sociétés de notre groupe (« Groupe »). En gérant le Site, nos collaborateurs peuvent avoir accès à et/ou traiter vos données personnelles. Le transfert de vos données personnelles aux autres sociétés du Groupe est basé sur nos intérêts légitimes en matière de support et d'administration interne. L'accès à vos données personnelles est strictement limité aux collaborateurs habilités à accéder à ces données.

                    <br />
                    Sika France peut également être amenée à transférer vos données personnelles aux organismes chargés de l'application des lois, autorités gouvernementales, juristes et consultants externes conformément à la loi applicable sur la protection des données. La base légale de ce traitement est, soit la nécessité de la conformité à une obligation légale à laquelle Sika France est soumise, soit nos intérêts légitimes, comme l'exercice ou la défense d'un droit en justice.<br />

                    La liste mise à jour des sous-traitants et des tiers avec lesquels nous partageons vos données est disponible sur demande en contactant le Délégué à la Protection des Données (« DPO ») dont les coordonnées sont précisées à l’article 5 de cette note.
                </p>
                <h3 className="mb-2% text-lg font-semibold  justify-center">c. Transferts internationaux des données personnelles
                </h3>
                <p className="text-justify font-normal text-sm mb-2%">Vos données personnelles peuvent être transférées vers et traitées par des destinataires qui se trouvent en-dehors de l'Espace économique européen (« EEE ») et qui ne fournissent pas un niveau approprié de protection des données. Dans la mesure où vos données personnelles sont transférées vers des pays qui ne fournissent pas un niveau approprié de protection des données du point de vue de la Commission européenne, le transfert de vos données sera encadré par des garanties appropriées, telles que les clauses contractuelles types conformes au modèle adopté par la Commission européenne. Vous pouvez demander une copie des garanties mises en place en contactant le DPO dont les coordonnées sont précisées dans l’article 5. L'accès est strictement limité aux destinataires habilités à accéder à ces données.
                </p>
                <h2 className="text-lg font-bold justify-center mt-6% mb-2% ">3. QUELS DROITS AVEZ-VOUS ET COMMENT FAIRE VALOIR VOS DROITS ?</h2>
                <p className="text-justify font-normal text-sm mb-2%">Si vous avez déclaré consentir au traitement de vos données personnelles, vous pouvez retirer ce consentement à tout moment. Le retrait de votre consentement n’aura d’effet que pour le futur et n'affectera pas la licéité du traitement effectué avant ce retrait.<br />

                    Conformément à la loi applicable sur la protection des données, vous pouvez demander l'accès à vos données personnelles, demander la rectification de vos données personnelles ; demander l'effacement de vos données personnelles, demander la limitation de traitement de vos données personnelles ; demander la portabilité de vos données personnelles et faire objection au traitement de vos données personnelles. Veuillez noter que ces droits précédemment mentionnés peuvent être restreints par la loi nationale applicable sur la protection des données. Pour de plus amples informations sur ces droits, veuillez-vous reporter à l'annexe « Vos Droits ».<br />

                    Vous pouvez également porter plainte auprès de l'autorité de contrôle de la protection des données compétente. Pour exercer vos droits, veuillez contactant le DPO dont les coordonnées sont précisées dans l’article 5 ou accéder au formulaire de contact disponible au bas de la page d’accueil du site web de la société <a href="https://fra.sika.com/" target="_blank" rel="noopener noreferrer" className="text-blue-700 active:text-blue-800">www.fra.sika.com</a> via la rubrique « Exercez Vos Droits ».
                </p>
                <h2 className="text-lg font-bold justify-center mt-6% mb-2%" >4. COMBIEN DE TEMPS GARDONS-NOUS VOS DONNÉES PERSONNELLES ?</h2>
                <p className="text-justify font-normal text-sm mb-2%">Vos données personnelles seront conservées tant que vous conservez la qualité d’utilisateur du Site.<br />

                    A l’expiration de ce délai, et lorsque Sika France n'a plus besoin d'utiliser vos données personnelles pour se conformer aux obligations contractuelles ou légales, vos données personnelles seront supprimées de nos systèmes et enregistrements et/ou nous prendrons des mesures pour les anonymiser, de sorte que vous ne puissiez plus être identifié.
                </p>
                <h2 className="text-lg font-bold justify-center mt-6% mb-2%" >5. NOUS CONTACTER</h2>
                <p className="text-justify font-normal text-sm mb-2%">Si vous avez des réclamations ou des questions concernant la présente note d’information relative au traitement de vos données sur le Site, veuillez nous contacter par courrier à l’adresse suivante :
                    Sika France SAS – A l’attention du Délégué à la protection des données – 84 rue Edouard Vaillant, 93350 Le Bourget - France
                    ou par courriel : <a href="mailto:privacy@fr.sika.com" target="_blank" rel="noopener noreferrer" className="text-blue-700 active:text-blue-800">privacy@fr.sika.com</a>
                </p>
                <h1 className="text-2xl font-bold mt-6%">Annexe : Vos droits
                </h1>
                <h3 className="mb-2% text-lg font-semibold  justify-center">a. Droit d'accès
                </h3>
                <p className="text-justify font-normal text-sm mb-2%">
                    Vous avez le droit d'obtenir de notre part une confirmation si vos données personnelles sont traitées ou non, et, si tel est le cas, vous pouvez demander à avoir accès à vos données personnelles. L'accès aux informations inclut notamment les finalités du traitement, les catégories de données personnelles concernées et les destinataires ou catégories de destinataires auxquels les données personnelles ont été ou seront divulguées. Toutefois, ce n'est pas un droit absolu et les intérêts des autres personnes peuvent limiter votre droit d'accès.
                    Vous pouvez avoir le droit d'obtenir une copie de vos données personnelles faisant l'objet du traitement. Si vous demandez d'autres copies, nous sommes autorisés à facturer des frais raisonnables basés sur les coûts administratifs.
                </p>
                <h3 className="mb-2% text-lg font-semibold  justify-center">b. Droit d'accès
                </h3>
                <p className="text-justify font-normal text-sm mb-2%">
                    Vous pouvez avoir le droit d'obtenir de notre part la rectification de vos données personnelles erronées. En fonction des finalités du traitement, vous pouvez avoir le droit de faire compléter des données personnelles incomplètes, y compris en fournissant une déclaration supplémentaire.
                </p>
                <h3 className="mb-2% text-lg font-semibold  justify-center">c. Droit d'effacement
                </h3>
                <p className="text-justify font-normal text-sm mb-2%">
                    Dans certaines circonstances, vous pouvez avoir le droit d'obtenir de notre part l'effacement de vos données personnelles et nous pouvons être obligés d'effacer ces données personnelles.
                </p>
                <h3 className="mb-2% text-lg font-semibold  justify-center">d. Droit de limitation de traitement
                </h3>
                <p className="text-justify font-normal text-sm mb-2%">
                    Dans certaines circonstances, vous pouvez avoir le droit d'obtenir de notre part la limitation du traitement de vos données personnelles. Dans ce cas, les données respectives seront marquées et nous pourrons les traiter seulement pour certaines finalités.
                </p>
                <h3 className="mb-2% text-lg font-semibold  justify-center">e. Droit de portabilité des données
                </h3>
                <p className="text-justify font-normal text-sm mb-2%">
                    Dans certaines circonstances, vous pouvez avoir le droit de recevoir vos données personnelles, que vous nous avez fournies, sous un format structuré, utilisé couramment et lisible par une machine et vous pouvez avoir le droit de transmettre ces données à une autre entité sans que nous y fassions obstacle.
                </p>
                <h3 className="mb-2% text-lg font-semibold  justify-center">f. Droit d’opposition
                </h3>
                <p className="text-justify font-normal text-sm mb-2%">
                    Dans certaines circonstances, vous pouvez avoir le droit de faire objection, pour des motifs en lien avec une situation particulière, ou lorsque vos données personnelles sont traitées à des fins de marketing direct, à tout moment pour le traitement de vos données personnelles par nos soins et nous pouvons être amenés à ne plus traiter vos données personnelles.
                    De plus, si vos données personnelles sont traitées à des fins de marketing direct, vous pouvez avoir le droit de faire objection à tout moment au traitement de vos données personnelles pour ce marketing, qui inclut le profilage dans la mesure où cela serait en rapport avec ce marketing direct. Dans ce cas, nous ne traiterons plus vos données personnelles pour de telles finalités.
                </p>
            </div>
        </div>
    )
}