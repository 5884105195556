import { IoIosArrowBack } from "react-icons/io";
import { Link } from "react-router-dom";

export default function Mentions() {
    return (
        <div className="items-start flex flex-col mx-4%">
            <div className="items-center flex mt-3">
                <Link to="/">
                    <IoIosArrowBack className="w-10 h-12" />
                </Link>
                <h1 className="text-2xl font-bold">Mentions légales et conditions générales d'utilisation du site
                </h1>
            </div>
            <div className="my-2%">
                <h2 className="text-lg font-bold justify-center underline mt-4% mb-2%"> MENTIONS LEGALES
                </h2>
                <h3 className="mb-2% text-lg font-semibold  justify-center">Editeur
                </h3>
                <p className="text-justify font-normal text-sm mb-2%">Le site <a href="https://fra.sika.com/" target="_blank" rel="noopener noreferrer" className="text-blue-700 active:text-blue-800">www.fra.sika.com</a> (ci-après le «Site») est détenu et exploité par SIKA France SAS, société par actions simplifiées, au capital de 418 018 260 euros,
                    immatriculée au R.C.S. de Bobigny, sous le numéro  572 232 411 dont le siège est situé
                    84 Rue Edouard Vaillant – 93350 Le Bourget– <a href="tel:0149928000" target="_blank" rel="noopener noreferrer" className="text-blue-700 active:text-blue-800" >Tél 01 49 92 80 00.</a> <br />SIKA France SAS est une société contrôlée par Sika AG. < br />
                    La création graphique et l’intégration du site sont gérées par <a href="https://juniorisep.com/" target="_blank" rel="noopener noreferrer" className="text-blue-700 active:text-blue-800">Juniorisep</a>.
                </p>
                <h3 className="mb-2% text-lg font-semibold  justify-center">Hébergeur
                </h3>
                <p className="text-justify font-normal text-sm mb-2%">GANDI</p>
                <h3 className="mb-2% text-lg font-semibold  justify-center">Limitation d'accès
                </h3>
                <p className="text-justify font-normal text-sm mb-2%">Les informations auxquelles vous accédez sur le présent Site web (le "Site") ne doivent pas être utilisées dans aucun pays ni devant aucune juridiction ni par aucune personne, dans le cas où leur utilisation pourrait constituer une violation du droit. Si vous êtes dans cette situation, il vous est interdit d’accéder ou d’utiliser les informations diffusées sur ce Site.</p>
                <h2 className="text-lg font-bold justify-center underline mt-4% mb-2%"> CONDITIONS D'UTILISATION

                </h2>
                <p className="text-justify font-normal text-sm mb-2%">Merci de lire attentivement les Conditions Générales d’Utilisation (les "Conditions d'utilisation") qui se présenteront à vous lors de chaque accès et/ou utilisation du présent Site. En accédant au présent Site et aux pages qu’il contient, vous acceptez nos Conditions d'Utilisation. <br />
                    Si vous n'êtes pas en accord avec nos Conditions d'Utilisation, merci de ne pas accéder au présent Site. Les Conditions d'Utilisation peuvent être modifiées à tout moment sans préavis.<br />
                    Le Site et ses Conditions d'Utilisation sont régis par le droit français.
                </p>
                <h2 className="text-lg font-bold justify-center  mt-4% mb-2%">1. EXONÉRATION DE RESPONSABILITÉ
                </h2>
                <p className="text-justify font-normal text-sm mb-2%">
                    Toutes les informations contenues sur le présent Site sont fournies « telles quelles » en l'état de nos connaissances à ce jour, et n'impliquent aucune garantie de quelque nature que ce soit, tant expresse que tacite, notamment aucune garantie en ce qui concerne la qualité marchande et l’adaptation à un objectif particulier.< br />
                    Aucun engagement ni garantie ne sera accordé(e) sur le contenu, la fonctionnalité, les liens ou communications, y compris aucun engagement ni garantie en cas de défauts, erreurs ou corrections de ces données, en cas de transmissions interrompues ou interceptées, pertes de données, ou en cas de virus ou de tout autre composant endommagé.< br />
                    En aucun cas la Société, ni aucune de ses filiales ou sociétés affiliées ne seront responsables de quelque manière que ce soit des coûts ou dommages, y compris notamment les dommages directs, indirects, particuliers, incidents ou consécutifs, résultant de ou liés à l'accès, l'utilisation, un problème d’accès ou d’utilisation du présent Site, de ses fonctions ou caractéristiques, y compris en relation avec la navigation, le téléchargement de renseignements, données, texte, image, ou autre information accessible sur le présent Site ou tout autre site web lié ou reliant vers le présent Site.< br />
                    Il est de la responsabilité de l'utilisateur du présent Site de vérifier l'exactitude, l'intégralité, la fiabilité et l'utilité de tout avis, service ou autre information qui sont fournis. Toute information fournie par le présent Site ne doit pas être interprétée ni considérée comme un conseil juridique, comptable, fiscal, financier, en investissement ou tout autre conseil professionnel, ni comme un conseil sur un point spécifique.< br />
                    En conséquence, aucune responsabilité ne pourra être engagée pour une utilisation ou mauvaise utilisation d'une telle information. Les utilisateurs du présent Site devront consulter leur conseiller professionnel en fonction de leur propre situation.
                </p>
                <h2 className="text-lg font-bold justify-center  mt-4% mb-2%">2. ABSENCE D'OFFRE
                </h2>
                <p className="text-justify font-normal text-sm mb-2%">Aucune information sur le présent Site ne pourra être considérée comme une sollicitation, une offre, un conseil, une recommandation ou tout autre service en vue d'obtenir un engagement ou une transaction. Pour tout renseignement sur un produit spécifique et/ou pays, merci de vous connecter sur le Site web concerné.</p>
                <h2 className="text-lg font-bold justify-center  mt-4% mb-2%">3. PROPRIÉTÉ INTELLECTUELLE
                </h2>
                <p className="text-justify font-normal text-sm mb-2%">Sauf indication contraire, toutes les informations, texte, images graphiques, fonctions et caractéristiques et mise en page (y compris la "perception et la présentation") contenus sur le présent Site sont la propriété exclusive de la Société et ne pourront être ni copiés ni diffusés, en tout ou partie, sans le consentement exprès écrit de la Société.< br />
                    Toutefois, vous pouvez télécharger ou imprimer toute page du présent Site pour une utilisation personnelle, non commerciale, à condition que vous ne supprimiez pas les mentions de copyright.
                </p>
                <h2 className="text-lg font-bold justify-center  mt-4% mb-2%">4. LIENS
                </h2>
                <p className="text-justify font-normal text-sm mb-2%">Les liens depuis le présent Site vers d'autres Sites web sont fournis seulement pour votre information et convenance.< br />
                    La Société n'assume aucune responsabilité ou engagement à l’égard d'aucun site web lié au présent Site (ni d'aucun site web reliant vers le présent Site) y compris son contenu et son fonctionnement. La Société ne révise pas et ne contrôle pas ces liens. Certains sites web peuvent être situés en dehors de votre pays et de ce fait peuvent être soumis à des réglementations et obligations différentes.< br />
                    Un lien depuis le présent Site vers un autre site web (ou un lien en provenance d'un autre site web vers le présent Site) ne peut constituer une référence, une approbation, une caution, une publicité, une offre ou une sollicitation au regard de l'autre site web, ni au regard des contenus, produits ou services promus ou distribués au travers de cet autre site web.< br />
                    La Société considère que les liens qu'elle fournit vers d'autres site web publiquement accessibles, pages web, forums de discussion, et autres sources sont autorisés par la loi et correspondent aux attentes courantes et habituelles des utilisateurs d'internet.< br />
                    Aucune personne ni aucun site web ne sont autorisés à créer un lien avec le présent Site, que ce soit à partir d’une page d'accueil ou d’une page interne, sans l'autorisation écrite préalable de la Société.
                </p>
                <h2 className="text-lg font-bold justify-center  mt-4% mb-2%">5. POLITIQUE DE CONFIDENTIALITÉ
                </h2>
                <p className="text-justify font-normal text-sm mb-2%">
                    La Société prend toutes les mesures nécessaires pour garder sécurisées toutes les informations obtenues de la part des visiteurs du Site y compris données personnelles contre tout accès et usage non autorisés : la Société révise régulièrement ces mesures de sécurité.< br />
                    Vous ne devez en aucun cas transmettre à la Société d'information confidentielle au travers du Site. La transmission d'information à la Société ne saurait d'aucune manière constituer un engagement ni un quelconque accord de confidentialité entre vous et la Société. Toute information reçue par la Société au travers du Site, autre que l'information sur votre identité, est considérée comme non confidentielle.< br />
                    Concernant le traitement de vos données personnelles lorsque vous accédez au Site, merci de consulter la <a href="/politique" className="font-bold underline">POLITIQUE DE CONFIDENTIALITE RELATIVE A LA PROTECTION DES DONNEES PERSONNELLES</a>
                </p>
                <h2 className="text-lg font-bold justify-center  mt-4% mb-2%">6. CONTRÔLE
                </h2>
                <p className="text-justify font-normal text-sm mb-2%">La Société n'est pas obligée de contrôler les données qui sont soumises ou transmises à ou au travers du présent Site, ou d'un autre Site relié ou rattaché au présent Site. La Société toutefois se réserve le droit de contrôler ces mêmes données, y compris sur les forums de discussions ou autres supports web. Vous reconnaissez qu'aucune obligation de quelque nature que ce soit ne sera attachée à ces données et que la soumission ou transmission de ces données ne saurait d'aucune manière constituer un engagement ni un quelconque accord de confidentialité entre vous et la Société.</p>
                <h2 className="text-lg font-bold justify-center  mt-4% mb-2%">7. MODALITÉS D'UTILISATION
                </h2>
                <p className="text-justify font-normal text-sm mb-2%">A chaque fois que vous accédez à ou utilisez le présent Site, vous vous engagez à ne pas :
                </p>
                <ul className="list-disc self-start ml-4% mb-2%">
                    <li className="text-justify font-normal text-sm mb-2%">Envoyer ni transmettre des informations illégales, menaçantes, abusives, diffamatoires, obscènes, pornographiques, blasphématoires ou autre information choquante et/ou dérangeante, y compris sans limitation des transmissions constituant ou encourageant une conduite qui pourrait constituer une offense criminelle, ou engager une responsabilité civile ou violer un droit national ou international.</li>
                    <li className="text-justify font-normal text-sm mb-2%">Envoyer ni transmettre des informations ou logiciels contenant un virus, ver informatique, cheval de troie ou tout autre composant dommageable.</li>
                    <li className="text-justify font-normal text-sm mb-2%">Interférer le fonctionnement du présent Site ni entraver ni empêcher tout autre utilisateur d'utiliser le présent Site. </li>
                    <li className="text-justify font-normal text-sm mb-2%">Envoyer, publier, transmettre, reproduire, distribuer ni exploiter des informations ou logiciels ou autre matériel obtenu au travers du présent Site à des fins commerciales, sans le consentement préalable écrit de la Société.</li>
                </ul>
                <p className="text-justify font-normal text-sm mb-2%">Toute tentative de la part de quiconque d'endommager le présent Site ou d'entraver son fonctionnement constituera une violation du code civil ou pénal. La Société se réserve le droit d'engager des poursuites et réclamer des dommages et intérêts au contrevenant.
                </p>
                <h2 className="text-lg font-bold justify-center  mt-4% mb-2%">8. LOI APPLICABLE, JURIDICTION
                </h2>
                <p className="text-justify font-normal text-sm mb-2%">Le présent Site et ses modalités d'utilisation sont gouvernés par le droit français. Les Tribunaux de Paris seront seuls compétents pour connaître de tout différend pouvant s’élever entre la Société et un tiers, concernant le fonctionnement du présent Site, l'exécution et/ou l’interprétation de ses fonctions et modalités d'utilisation. < br />
                    La Société déclare que les informations, matériels ou caractéristiques/fonctions du Site peuvent ne pas être utilisables dans le cadre d'un autre droit et/ou juridiction. Le présent Site ne s'adresse pas aux personnes soumises à un autre droit (en raison de leur nationalité, lieu de résidence, citoyenneté ou autre) où la publication ou l'accès au Site et à son contenu sont en contradiction avec le droit et règlements locaux. Les personnes soumises à ces restrictions et interdictions ne doivent en aucun cas accéder à ou utiliser le présent Site.< br />
                    Lorsque vous accédez ou utilisez le présent Site, vous le faites de votre propre initiative et êtes seul responsable de la conformité avec le droit et les réglementations locales applicables.< br />
                    Les mises à jour et modifications de ces conditions d'utilisation pourront être changées à tout moment.< br />
                    N'oubliez pas chaque fois que vous accédez à et/ou utilisez le présent Site de vérifier que les conditions d'utilisation sont celles en vigueur.
                </p>
            </div>
        </div>
    )
}