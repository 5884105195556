import { IoMdHome } from "react-icons/io";
import { Link } from "react-router-dom";

export default function Header() {
  return (
    <div className=" sticky top-0 z-10">
      <header className="p-4 bg-orange rounded-b-md shadow ">
        <div className="flex flex-wrap place-content-between items-end">
          <Link to="/">
            <img
              src={require("../img/SikaLogo.png")}
              alt="Logo Sika"
              className="w-12 ml-3"
            ></img>
          </Link>
          <Link to="/">
            <IoMdHome className="w-16 h-10 mr-2" />
          </Link>
        </div>
      </header>
    </div>
  );
}
