export default function StickyContact() {
  return (
    <div className="fixed bottom-0 right-0 mb-10 mr-5 bg-orange p-3 shadow-md rounded-full z-10">
      <a
        href="https://fra.sika.com/fr/contact/particuliers.html"
        target="_blank"
        rel="noopener noreferrer"
        className="text-xs no-underline"
      >
        <p className="">Contact</p>
        {/* <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          className="w-5 h-5"
        >
          <path
            fill-rule="evenodd"
            d="M3.43 2.524A41.29 41.29 0 0110 2c2.236 0 4.43.18 6.57.524 1.437.231 2.43 1.49 2.43 2.902v5.148c0 1.413-.993 2.67-2.43 2.902a41.102 41.102 0 01-3.55.414c-.28.02-.521.18-.643.413l-1.712 3.293a.75.75 0 01-1.33 0l-1.713-3.293a.783.783 0 00-.642-.413 41.108 41.108 0 01-3.55-.414C1.993 13.245 1 11.986 1 10.574V5.426c0-1.413.993-2.67 2.43-2.902z"
            clipRule="evenodd"
          />
        </svg> */}
      </a>
    </div>
  );
}
