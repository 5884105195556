import React from "react";

export default function Footer() {
  return (
    <div className="">
      <footer className="p-1 bg-orange rounded-t-md shadow-2xl ">
        <ul className="flex flex-wrap place-content-evenly gap-2">
          <li>
            <a href="/politique" className=" underline text-xs ">
              Politique de confidentialité
            </a>
          </li>
          <li>
            <a href="/mentions" className=" underline text-xs ">
              Mentions Légales
            </a>
          </li>
          <li>
            <a
              href="https://fra.sika.com/"
              target="_blank"
              rel="noopener noreferrer"
              className=" underline text-xs"
            >
              Site officiel
            </a>
          </li>
        </ul>
      </footer>
    </div>
  );
}
