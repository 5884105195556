import StickyContact from "./StickyContact";
import React from "react";
import { IoIosArrowBack } from "react-icons/io";
import SocialNetworkBar from "./SocialNetworkIcons";

interface ReplacedProductProps {
  state: any;
  productsData: any;
  update: (path: any) => void;
  setIsReplacedSelected: (value: boolean) => void;
}

export const ReplacedProduct: React.FC<ReplacedProductProps> = ({
  state,
  productsData,
  update,
  setIsReplacedSelected,
}) => {
  return (
    <div className=" flex flex-wrap mx-6% w-90%">
      <StickyContact />
      <div className="flex flex-wrap ">
        <div className="items-center flex mt-10%">
          <button onClick={() => update(-1)}>
            <IoIosArrowBack className="w-10 h-12" />
          </button>
          <div>
            <h1 id="myHeader" className="text-2xl font-bold  ">
              Solution
            </h1>
          </div>
        </div>
      </div>
      <div className=" text-xl font-regular flex flex-wrap justify-content-enter mt-2%">
        <p>Voici un produit de remplacement adapté à votre besoin :</p>
      </div>
      <div className="flex flex-col items-center justify-items-center  ">
        <h1 className="font-bold text-xl self-start mt-5% mb-4%">
          {productsData[state.replacedProduct].name}
        </h1>
        {productsData[state.replacedProduct].img && (
          <img
            src={
              require(`../img/imgProduit/${
                productsData[state.replacedProduct].img
              }`) || undefined
            }
            alt="Produit"
            className="h-80 max-w-full"
          />
        )}
        <p className="italic mt-3">
          {productsData[state.replacedProduct].comment}
        </p>
        <h1 className="font-bold text-xl self-start my-5% ">
          Avantages Produit
        </h1>
        {productsData[state.replacedProduct].avantage &&
          productsData[state.replacedProduct].avantage.map((value: any) => (
            <ul className="list-disc self-start ml-5% mb-3% leading-5">
              <li>{value}</li>
            </ul>
          ))}
        <h1 className="font-bold text-xl self-start my-5% ">
          Infos Techniques
        </h1>
        {productsData[state.replacedProduct].technique &&
          productsData[state.replacedProduct].technique.map((value: any) => (
            <ul className="list-disc self-start ml-5% mb-3% leading-5">
              <li>{value}</li>
            </ul>
          ))}
        <h1 className="font-bold text-xl self-start my-5% ">
          Infos Pratiques{" "}
        </h1>
        {productsData[state.replacedProduct].pratique &&
          productsData[state.replacedProduct].pratique.map((value: any) => (
            <ul className="list-disc self-start ml-5% mb-3% leading-5">
              <li>{value}</li>
            </ul>
          ))}
        <a
          href={productsData[state.replacedProduct].link}
          target="_blank"
          rel="noopener noreferrer"
          className="text-orange my-3"
        >
          Lien vers la Notice Technique
        </a>
        {state.replacedProduct && (
          <div className="flex rounded-lg bg-orange justify-center px-5 my-2">
            <a
              href="#top"
              className="flex  py-4%
                text-black text-base items-center"
              onClick={(e) => {
                e.preventDefault(); // Prevents the default anchor behavior
                window.scrollTo(0, 0); // Scrolls to the top
                setIsReplacedSelected(false); // Sets the state
              }}
            >
              Retourner au produit conseillé
            </a>
          </div>
        )}{" "}
        <SocialNetworkBar />
        <p className="font-normal text-c text-justify my-2%">
          Les informations et produits proposés par le présent guide ne sont pas
          de nature contractuelle, l’Utilisateur devra impérativement valider
          l’utilisation des produits proposés par le guide en contactant la
          hotline SIKA à l’adresse{" "}
          <a
            href="mailto:hotline@fr.sika.com"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-700 active:text-blue-800"
          >
            hotline@fr.sika.com
          </a>
          . A défaut la responsabilité de la société SIKA ne saurait être
          engagée.
        </p>
      </div>
    </div>
  );
};
